const paciente={
    criado_em:null,
    nome:null,
    email:null,
    contato:null,
    tempo_diabetes:null,
    dt_nascimento:null,
    glicemia_capilar:null,
    glicemia_plasmatica:null,
    hemoglobina_glicada:null,
    od_sem_alteracao:null,
    od_rdnp_leve:null,
    od_rdnp_moderada:null,
    od_rdnp_grave_m_grave:null,
    od_rdp:null,
    od_edema_macular:null,
    os_sem_alteracao:null,
    os_rdnp_leve:null,
    os_rdnp_moderada:null,
    os_rdnp_grave_m_grave:null,
    os_rdp:null,
    os_edema_macular:null,
    catarata:null,
    glaucoma:null,
    outras:null,
    observacoes_doencas_olhos:null,
    observacoes:null,
    orientar:null
};
export default paciente;